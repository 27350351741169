export const modelListMixin = {
    data() {
        return {
            loading: {
                data: false,
                search: false
            },
            data: null,
            filters: {
                limit : 10,
                sort: '',
                dir: 'asc',
                page : 1
            }
        };
    },
    methods: {
        getPage(page) {
            this.filters.page = page;
            this.getData();
        },
        search () {
            this.filters.page = 1;
            this.loading.search = true;
            this.getData();
        },
        setSort (field) {
            this.filters.page = 1;
            if (this.filters.sort === field) {
                this.filters.dir = this.filters.dir === 'asc' ? 'desc' : 'asc';
            }else{
                this.filters.sort = field;
            }

            this.getData();
        },
    }
}

