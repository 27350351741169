// export default async function checkForUpdate(context, err, firstTime = true) {
    // if ("serviceWorker" in navigator) {
    //     await navigator.serviceWorker.getRegistration()
    //     navigator.serviceWorker.getRegistrations()
    //       .then(function(registrations) {
    //           for(let registration of registrations) {
    //               if (registration && registration.waiting) {
    //                   registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    //               }
    //               //registration.unregister(); //hmmm maybe or not
    //           }
    //             console.log('reload')
    //           // window.location.reload()
    //       });
    //
    // }
    // setTimeout(() => checkForUpdate(context, err, false), 5000);
// }

// async function detectSWUpdate() {
//     const registration = await navigator.serviceWorker.ready;
//
//     registration.addEventListener("updatefound", event => {
//         console.log('updatefound')
//         const newSW = registration.installing;
//         newSW.addEventListener("statechange", event => {
//             console.log(newSW.state)
//             if (newSW.state == "installed") {
//                 window.location.reload()
//                 // New service worker is installed, but waiting activation
//             }
//         });
//     })
// }

export default async (context) => {
    const workbox = await window.$workbox;

    if (!workbox) {
        // alert("Workbox couldn't be loaded.");
        console.debug("Workbox couldn't be loaded.");
        return;
    }

    workbox.addEventListener('installed', (event) => {
        if (event.isUpdate) {
            // alert('There is an update for the PWA, reloading...');
            console.debug('There is an update for the PWA, reloading...');
            window.location.reload();
            return;
        }
        // alert('The PWA is on the latest version.')
        console.debug('The PWA is on the latest version.');
    });
};

